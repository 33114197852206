import React, { useState } from "react"
import { graphql } from "gatsby"
import {
  Container,
  Row,
  Col,
  Button,
  Alert,
  Form
} from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowAltCircleRight } from "@fortawesome/free-regular-svg-icons"
import axios from "axios"
import classNames from "classnames"
import queryString from "query-string"
import Link from "../UI/Link"
import {
  greenDivClass,
  inputDiv,
  sendBtnClass
} from "./Feedback.module.scss"

export const fragment = graphql`
  fragment feedbackFragment on WpPage_Flexlayouts_FlexibleLayouts_Feedback {
    feedbackQuestion
    yesAnswer
    yesResponse
    noAnswer
    noResponse
    messageSentConfirmation
    paragraph
    links {
      linkTo {
        title
        url
      }
    }
  }
`

const Feedback = ({
  feedbackQuestion,
  yesAnswer,
  noAnswer,
  yesResponse,
  noResponse,
  messageSentConfirmation,
  paragraph,
  links
}) => {
  const [showNoResponse, setShowNoResponse] = useState(true)
  const [showYesResponse, setShowYesResponse] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState()
  const [status, setStatus] = useState({
    initial: true
  })
  const [formValidated, setFormValidated] = useState(false)

  const handleButtonClick = () => {
    setShowNoResponse(false)
    setShowYesResponse(false)
  }

  const onClick = () => {
    setShowYesResponse(true)
    setShowNoResponse(true)
  }

  //form
  const validate = event => {
    const form = event.currentTarget

    if (form.checkValidity() === true) {
      event.stopPropagation()
      setFormValidated(true)
    } else {
      setFormValidated(false)
    }
  }

  const handleSubmit = event => {
    event.preventDefault()

    const form = event.currentTarget

    if (form.checkValidity() === false) {
      event.stopPropagation()
      setFormValidated(false)
      return
    } else {
      setFormValidated(true)
    }

    const pageTitle = document.title.slice(0, -5)

    const data = {
      pageTitle: pageTitle,
      comment: form.elements.comment.value
    }

    const postForm = async () => {
      try {
        setIsSubmitting(true)
        const response = await axios.post(
          `${process.env.FEEDBACK_FORM_URL}`,
          queryString.stringify(data)
        )

        setStatus({
          success: "Thank you!",
          initial: false
        })
      } catch (exception) {
        setIsSubmitting(false)
        console.error(exception)
        setStatus({
          ...status,
          exception: `${exception.response.status}: ${exception.response.statusText}`
        })
      }
    }
    postForm()
  }

  const submitPositiveFeedback = () => {
    const pageTitle = document.title.slice(0, -5)
    const data = {
      pageTitle: pageTitle,
      comment: "A user found this page useful"
    }

    const postPositiveForm = async () => {
      try {
        const response = await axios.post(
          `${process.env.FEEDBACK_FORM_URL}`,
          queryString.stringify(data)
        )
      } catch (exception) {
        console.error(exception)
      }
    }
    postPositiveForm()
  }

  const buttonClassnames = classNames(
    "pb-1",
    formValidated ? "" : "disabled bg-white  text-grey"
  )

  return (
    <>
      <div className="mb-4 mb-sm-0 bg-pale-grey">
        <Container className="bg-white py-sm-6">
          <div
            className={`${greenDivClass} pt-5 pb-4 my-5 my-sm-0 px-3 px-lg-8`}>
            <h3
              className="pb-4 text-md-center"
              dangerouslySetInnerHTML={{ __html: feedbackQuestion }}
            />
            <Row id="topForm">
              <Col sm={12} md={6} className="mb-3 mb-md-0">
              <div className="d-grid">
                  <Button variant="outline-secondary" onClick={onClick}>
                    <span dangerouslySetInnerHTML={{ __html: yesAnswer }}></span>
                  </Button>
                </div>
              </Col>

              <Col sm={12} md={6}>
                <div className="d-grid">
                  <Button variant="outline-secondary" onClick={handleButtonClick}>
                    <span dangerouslySetInnerHTML={{ __html: noAnswer }}></span>
                  </Button>
                </div>
              </Col>
            </Row>

            {showYesResponse ? (
              <Row>
                <Col>
                  <h3 className="pt-4 text-start">Thank you!</h3>
                  <p>{yesResponse}</p>
                </Col>
                {submitPositiveFeedback()}
              </Row>
            ) : null}

            {showNoResponse ? null : (
              <Form
                validated={formValidated}
                method="post"
                onSubmit={handleSubmit}
                onChange={validate}>
                {status && status.initial && (
                  <Row>
                    <Col>
                      <p
                        className="pb-0 pt-4 pe-lg-5"
                        dangerouslySetInnerHTML={{ __html: paragraph }}
                      />
                      {links.map((item, i) => {
                        return (
                          <div key={i}>
                            <Link
                              key={`links${i}`}
                              classes="linkClass"
                              link={item.linkTo.url}
                              title={item.linkTo.title}
                            />
                          </div>
                        )
                      })}

                      <h3 className="mt-5 mb-1">Contact us</h3>
                      <Link className="linkClass" to="/contact-us/">
                        Send us a message
                      </Link>

                      <h3 className="mt-4 mb-2">Leave us some feedback</h3>
                      <p className="mb-4">{noResponse}</p>

                      <Row className="pb-4">
                        <Col sm={12}>
                          <Form.Group
                            controlId="comment"
                            className="position-relative">
                            <Form.Control
                              as="textarea"
                              name="comment"
                              rows="4"
                              className={inputDiv}
                              required
                            />
                                <Button
                                  variant="link"
                                  className={`${sendBtnClass} linkClass p-0`}
                                  disabled={!formValidated}
                                  type="submit"
                                  onClick={() => {
                                    let elmnt = document.getElementById(
                                      "topForm"
                                    )
                                    elmnt.scrollIntoView()
                                  }}>
                                  send
                                  <FontAwesomeIcon
                                    icon={faArrowAltCircleRight}
                                    size="lg"
                                    className="ms-2"
                                  />
                                </Button>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}

                {status && status.success && (
                  <>
                    <Container>
                      <Row>
                        <Col>
                          <p
                            className={`pb-0 pt-4 pe-lg-5`}
                            dangerouslySetInnerHTML={{ __html: paragraph }}
                          />
                          {links.map((item, i) => {
                            return (
                              <div key={i}>
                                <Link
                                  key={`links${i}`}
                                  classes="linkClass"
                                  link={item.linkTo.url}
                                  title={item.linkTo.title}
                                />
                              </div>
                            )
                          })}

                          <h3 className="pt-4 text-start">Thank you!</h3>
                          <p>{messageSentConfirmation}</p>
                        </Col>
                      </Row>
                    </Container>
                  </>
                )}
                {status && status.exception && (
                  <Alert variant="danger" className="my-5">
                    <Alert.Heading>{status.exception}</Alert.Heading>
                  </Alert>
                )}
              </Form>
            )}
          </div>
        </Container>
      </div>
    </>
  )
}

export default Feedback